import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (
<Layout>
   <div className="mx-auto text-xl">
      <div className="font-bold text-4xl text-gray-800 clearfix mt-6 px-4">
         <Link to="/" >pikseladam</Link>
         <span className="text-xl text-gray-500">.com</span>
      </div>
      <div className=" text-blue-500 text-lg px-4">
         <div className="w-full">
            <img className="u-photo hidden" src="https://pikseladam.com/avatar.jpg" alt="pikseladam logo" />
            <p className="p-note hidden">I'm Lead PLM Systems & Software Engineer. I code stuff & design things. #Arts for art. Working on http://turrsu.com and https://pikseladam.com for fun. #webmentions on pikseladam.</p>
            <a href="https://github.com/tcgumus/" className="hover:underline mr-3" rel="me">github</a>
            <a href="https://micro.blog/pikseladam" rel="me" className="hover:underline mr-3">micro.blog</a>
            <a href="https://www.instagram.com/tcgumus/" rel="me" className="hidden">instagram</a>
            <a href="https://dev.to/tcgumus" className="hover:underline mr-3" rel="me">dev.to</a>
            <a href="https://twitter.com/tc_gumus" className="hover:underline mr-3" rel="me"> twitter</a> 
         </div>
      </div>
      <div className="w-3/4">
      <div className="bg-yellow-300 text-base font-bold p-4 mt-8 text-black">/USES</div>
      <div className="p-4 text-base">
         <div className=" mt-4 font-bold text-lg">Software I Use</div>
         <div>Here is list of the software i use while i write.</div>
         <div><a href="https://code.visualstudio.com" className="font-semibold py-3 hover:text-blue-500">Visual Studio Code</a><i> with <a href="https://marketplace.visualstudio.com/items?itemName=miguelsolorio.min-theme" className="font-semibold py-3 hover:text-blue-500">Min Theme</a>.</i></div>
         <div><a href="https://www.jetbrains.com/idea/" className="font-semibold py-3 hover:text-blue-500">IntelliJ IDEA</a><i> for java.</i></div>
         <div><a href="https://www.mamp.info/en/" className="font-semibold py-3 hover:text-blue-500">MAMP</a><i> as apache and mysql server.</i></div>
         <div><a href="https://basecamp.com" className="font-semibold py-3 hover:text-blue-500">Basecamp 3</a><i> app to take notes.</i></div>
         <div><a href="https://iterm2.com" className="font-semibold py-3 hover:text-blue-500">iTerm 2</a><i> with Oh-My-Zsh and powerline fonts as a replacement for Terminal.</i></div>
         <div><a href="https://yarnpkg.com" className="font-semibold py-3 hover:text-blue-500">Yarn</a><i> for JS packages.</i></div>
         <div><a href="https://brew.sh" className="font-semibold py-3 hover:text-blue-500">Homebrew</a><i> for software management.</i></div>
         <div><a href="https://www.typora.io" className="font-semibold py-3 hover:text-blue-500">Typora</a><i> to create, manage and organize documentations.</i></div>
         <div><a href="https://www.sketch.com" className="font-semibold py-3 hover:text-blue-500">Sketch APP</a><i> for design and mockups.</i></div>
         <div><a href="https://www.digitalocean.com" className="font-semibold py-3 hover:text-blue-500">Digital Ocean</a> and <a href="https://www.netlify.com" className="font-semibold py-3 hover:text-blue-500">Netlify</a><i> for online projects.</i></div>

         <div className="mt-4 font-bold text-lg">Hardware I Would Recommend</div>
         <div className="mb-4">Of course i have a mac and pc but i don't think they are important. Important thing is that your keyboard <span role="img" aria-label=":)">😉</span>. I will recommend one of the best coder keyboard out there. Behold. <a href="https://www.hhkeyboard.com/uk/" className="font-semibold py-3 hover:text-blue-500">HHKB PRO 2!</a></div>
         <img src={'/key.jpg'} alt="HHKB Pro 2" />

      </div>
      </div> 
   </div>
</Layout>
)
